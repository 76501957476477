import { format } from 'date-fns';

const timeSlots = async () => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/timeslots/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const data = await res.json();
  const result = {};
  data.forEach((item) => {
    const day = item.slice(0, 10);
    const time = format(new Date(item), 'hh:mm a');
    if (!result[day]) {
      result[day] = [];
    }
    result[day].push(time);
  });
  return result;
};

export default timeSlots;

export const getBookingSlotsAPI = async ({ id, order, meetingUid, createdBy }) => {
  let url = `${process.env.REACT_APP_API_URL}/booking/slots/?`;

  if (createdBy) {
    url += `&created_by=${createdBy}`;
  }
  if (meetingUid) {
    url += `&meeting_uid=${meetingUid}`;
  }
  if (order) {
    url += `&order_uid=${order}`;
  }
  if (id) {
    url += `&course_external_id=${id}`;
  }
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const data = await res.json();
  return data;
};

export const postBookingAPI = async (time) => {
  try {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/booking/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(time)
    });
    const data = await result.json();
    return data;
  } catch (e) {
    return 'error';
  }
};

export const postBookingRescheduleAPI = async (time) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/booking/${time.meeting_uid}/reschedule/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(time)
    }
  );
  const data = await result.json();

  if (result.status < 300) {
    return data;
  }
  return false;
};

export const getBookedMeetingUidAPI = async (meetingUid) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/booking/${meetingUid}/`);
  if (result.status < 300) {
    const data = await result.json();
    return data;
  }
  return false;
};

export const postBookingCancelAPI = async (meetingUid) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/booking/${meetingUid}/cancel/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (result.status < 300) {
    return true;
  }
  return false;
};

export const postBookingLinkEmailAPI = async (email) => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/booking/link/email/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(email)
  });
  const result = await data.json();
  return result;
};

export const postTrackingLogAPI = async (event, data = {}) => {
  await fetch(`${process.env.REACT_APP_API_URL}/tracking_log/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ event, data })
  });
};

export const getGoogleEventOwnerAPI = async () => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/google-event-owner/`);
  const res = await data.json();
  return res;
};
